import React from "react";
import "./SkillsBottom.css";

import cold_snap from "../../assets/skills/cold_snap.png";
import ghost_walk from "../../assets/skills/ghost_walk.png";
import tornado from "../../assets/skills/tornado.png";
import emp from "../../assets/skills/emp.png";
import alacrity from "../../assets/skills/alacrity.png";
import deafening_blast from "../../assets/skills/deafening_blast.png";
import chaos_meteor from "../../assets/skills/chaos_meteor.png";
import ice_wall from "../../assets/skills/ice_wall.png";
import forge_spirit from "../../assets/skills/forge_spirit.png";
import sun_strike from "../../assets/skills/sun_strike.png";

import R from "../../assets/skills/invoke.png";
import Q from "../../assets/skills/quas.png";
import W from "../../assets/skills/wex.png";
import E from "../../assets/skills/exort.png";

import quas from "../../assets/skills/quas.png";
import wex from "../../assets/skills/wex.png";
import exort from "../../assets/skills/exort.png";
import unknown from "../../assets/skills/unknown.png";
import invoke from "../../assets/skills/invoke.png";

function SkillsBottom() {
  // let R = R;
  let keysImages = {
    Q: Q,
    W: W,
    E: E,
    // Add other keys and their corresponding images here
  };

  let lastThreeKeys = ["Q", "W", "E"];

  // Add an event listener for the keydown event
  // Add an event listener for the keydown event
  document.addEventListener("keydown", function (e) {
    // Get the key name
    let keyName = e.key.toUpperCase();

    // Check if the key is one of the keys you're interested in
    if (["Q", "W", "E"].includes(keyName)) {
      // Add the new key to the beginning of the array
      lastThreeKeys.push(keyName);

      // If there are more than three keys in the array, remove the last one
      if (lastThreeKeys.length > 3) {
        lastThreeKeys.shift();
      }

      // Update the img elements with the images corresponding to the last three keys
      for (let i = 0; i < lastThreeKeys.length; i++) {
        document.getElementById(`pressedKeyImage${i + 1}`).src =
          keysImages[lastThreeKeys[i]];
      }
    }
  });

  let score = localStorage.getItem("score") || 0; // Get the score from sessionStorage, or set it to 0 if it doesn't exist
  document.getElementById("score").textContent = "Score: " + score;

  document.addEventListener("keydown", function (event) {
    if (event.key.toUpperCase() === "R") {
      // Get all blocks
      const blocks = document.querySelectorAll(".block");

      // Iterate over each block
      for (let block of blocks) {
        // Get the block's key
        const blockKeyList = Array.from(block.classList)
          .find((cls) => spells[cls])
          .split("");
        //console.log(blockKeyList.sort());
        //console.log(lastThreeKeys.sort());
        if (
          JSON.stringify(blockKeyList.sort()) ===
          JSON.stringify(lastThreeKeys.sort())
        ) {
          score++;
          localStorage.setItem("score", score); // Store the score in sessionStorage
          document.getElementById("score").textContent = "Score: " + score;
          block.remove();
          break;
        }
      }
    }
  });

  //

  let images = {
    cold_snap,
    ghost_walk,
    tornado,
    emp,
    alacrity,
    deafening_blast,
    chaos_meteor,
    ice_wall,
    forge_spirit,
    sun_strike,
  };
  var spells = {
    QQQ: { name: "cold_snap", key: "QQQ" },
    QQW: { name: "ghost_walk", key: "QQW" },
    QWW: { name: "tornado", key: "QWW" },
    WWW: { name: "emp", key: "WWW" },
    WWE: { name: "alacrity", key: "WWE" },
    QWE: { name: "deafening_blast", key: "QWE" },
    WEE: { name: "chaos_meteor", key: "WEE" },
    QQE: { name: "ice_wall", key: "QQE" },
    QEE: { name: "forge_spirit", key: "QEE" },
    EEE: { name: "sun_strike", key: "EEE" },
  };

  var spellKeys = Object.keys(spells);

  // Get the container element
  const container = document.getElementById("container");

  // Function to create a new block
  function createBlock() {
    const block = document.createElement("div");
    block.classList.add("block");
    container.appendChild(block);

    // Set random position for the block
    const randomX = Math.floor(Math.random() * window.innerWidth - 199);
    block.style.left = randomX + "px";

    // Set random image for the block
    const randomSpellKey =
      spellKeys[Math.floor(Math.random() * spellKeys.length)];
    block.classList.add(randomSpellKey);
    const spellName = spells[randomSpellKey].name;
    const imageUrl = images[spellName];
    block.style.backgroundImage = `url(${imageUrl})`;
    block.style.backgroundSize = "cover"; // Stretch the image to fill the block

    // Create overlay with spell key
    const overlay = document.createElement("div");
    overlay.classList.add("spell-overlay");
    overlay.textContent = randomSpellKey;
    block.appendChild(overlay);

    // Animate the block falling
    const animationDuration = Math.floor(Math.random() * (10 - 5 + 1) + 5);
    block.style.animationDuration = animationDuration + "s";

    // Remove the block after animation ends
    block.addEventListener("animationend", () => {
      container.removeChild(block);
    });
  }
  // Function to create multiple blocks
  function createBlocks(numBlocks) {
    const currentBlocks = document.getElementsByClassName("block").length;
    const remainingBlocks = 10 - currentBlocks;
    const blocksToCreate = Math.min(numBlocks, remainingBlocks);
    for (let i = 0; i < blocksToCreate; i++) {
      createBlock();
    }
  }

  // Call the createBlocks function to create 10 blocks initially
  createBlocks(10);

  // Call the createBlocks function every 2 seconds to create new blocks
  setInterval(() => {
    createBlocks(3);
  }, 2000);

  // Ping the server every 10 seconds to get the round trip time in milliseconds
  // and display it on the page
  // setInterval(async function () {
  //   const start = new Date().getTime();

  //   const response = await fetch("/ping");

  //   const end = new Date().getTime();

  //   const roundTripTime = end - start;
  //   document.getElementById("ping").textContent = `${roundTripTime} ms`;
  // }, 10000);

  return (
    <div
      id="QWECointainer"
      className="flex items-center space-x-2 bottom-0 absolute z-50 left-[1100px]"
    >
      <img id="pressedKeyImage1" src={quas} />
      <img id="pressedKeyImage2" src={wex} />
      <img id="pressedKeyImage3" src={exort} />
      <img id="" src={unknown} />
      <img id="" src={unknown} />
      <img id="" src={invoke} />
    </div>
  );
}

export default SkillsBottom;

import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import "./Background.css"; // Import the CSS

// used in background css
import BackgroundImage from "../../assets/background/background.webp";

import invokerQuas from "../../assets/loader/invoker_quas.webp";
import invokerWex from "../../assets/loader/invoker_wex.webp";
import invokerExort from "../../assets/loader/invoker_exort.webp";

function Background({ isVideoDisabled }) {
  const [isVideoReady, setVideoReady] = useState(false);

  const videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 0, // Hides the video player controls
      disablekb: 1, // Disables keyboard controls
      loop: 1,
      // playlist: "SCC_8RdgYzo", // This will loop the same video
      modestbranding: 1, // Hides YouTube logo
      showinfo: 0, // Hides video info
      rel: 0, // Prevents related videos from showing at the end
      fs: 0,
      cc_load_policty: 0,
      iv_load_policy: 3, // Hides video annotations
      autohide: 1,
      mute: 1, // Mutes the video
      vq: "highres", // Add this line
      start: 15,
    },
  };

  let intervalId;
  let playStartTime;

  const onStateChange = (event) => {
    if (event.data === YouTube.PlayerState.PLAYING) {
      console.log(isVideoReady);
      console.log(event.data);
      // Record the start time of the video
      playStartTime = Date.now();

      // Clear any existing interval
      if (intervalId) {
        clearInterval(intervalId);
      }

      // Set a new interval to check the player's state every second
      intervalId = setInterval(() => {
        // If the video has been playing for 4 seconds straight
        if (Date.now() - playStartTime >= 2000) {
          console.log("Video is ready" + intervalId);
          // Clear the interval
          clearInterval(intervalId);

          // Set isVideoReady to true
          setVideoReady(true);
        }
      }, 1000); // Check every second
    } else {
      // If the video is not playing, clear the interval and reset the start time
      if (intervalId) {
        clearInterval(intervalId);
      }
      playStartTime = null;
    }
  };

  useEffect(() => {
    // Reset isVideoReady to false when isVideoDisabled changes
    setVideoReady(false);
  }, [isVideoDisabled]);

  // video id list

  const videoIds = ["SCC_8RdgYzo", "8PfmWXMu-H4", "E_R6TUd-zYs", "IVt7yNOP-eI"];
  // get a random video id from the list
  const getRandomVideoId = () => {
    const index = Math.floor(Math.random() * videoIds.length);
    return videoIds[index];
  };
  const [randomId, setRandomId] = useState(getRandomVideoId());
  // *****************************************************
  return (
    <div
      style={{
        position: "fixed",
        // backgroundImage: `url(${BackgroundImage})`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        color: "white",
        width: "100vw",
        height: "100vh",
        zIndex: -2,
        // visibility: isVideoReady ? "hidden" : "visible",
      }}
    >
      {/* Adds an empty transparent div elemts that prevent clicking on iframe video */}
      <div
        style={{
          position: "fixed",
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 0,
          visibility: isVideoReady ? "hidden" : "visible",

          // backgroundColor: "black",
          // opacity: 0.5,
        }}
      ></div>
      {!isVideoDisabled && (
        <>
          <preloader
            style={{
              visibility: isVideoReady ? "hidden" : "visible",
            }}
          >
            {/* prettier-ignore */}
            <div>
          <div style={{ backgroundImage: `url(${invokerQuas})` }}></div> 
          <div style={{ backgroundImage: `url(${invokerWex})` }}></div> 
          <div style={{ backgroundImage: `url(${invokerExort})` }}></div> 
        </div>
          </preloader>
          <YouTube
            videoId={randomId}
            opts={videoOptions}
            onStateChange={onStateChange}
            // id="video"
            className="video"
          />
        </>
      )}
    </div>
  );
}

export default Background;

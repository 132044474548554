import React, { useEffect } from "react";

import favicon1 from "../../assets/favicons/favicon_1.png";
import favicon2 from "../../assets/favicons/favicon_2.png";
import favicon3 from "../../assets/favicons/favicon_3.png";
import favicon4 from "../../assets/favicons/favicon_4.png";
import favicon5 from "../../assets/favicons/favicon_5.png";
import favicon6 from "../../assets/favicons/favicon_6.png";
import favicon7 from "../../assets/favicons/favicon_7.png";
import favicon8 from "../../assets/favicons/favicon_8.png";
import favicon9 from "../../assets/favicons/favicon_9.png";
import favicon10 from "../../assets/favicons/favicon_10.png";

export const urls = [
  favicon1,
  favicon2,
  favicon3,
  favicon4,
  favicon5,
  favicon6,
  favicon7,
  favicon8,
  favicon9,
  favicon10,
];
const FaviconIcos = () => {
  useEffect(() => {
    const link = document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    const randomIndex = Math.floor(Math.random() * urls.length);
    link.href = urls[randomIndex];
    document.getElementsByTagName("head")[0].appendChild(link);
  }, []);

  return null;
};

export default FaviconIcos;

import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import FaviconIcos from "./components/layout/FaviconIcos";
import GlobalResize from "./components/layout/GlobalResize";
import Background from "./components/layout/Background";
import { useState, useEffect } from "react";
import { FaPlay, FaPause } from "react-icons/fa";

function BackgroundWithButton() {
  const [isVideoDisabled, setIsVideoDisabled] = useState(() => {
    const storedValue = localStorage.getItem("isVideoDisabled");
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });

  useEffect(() => {
    localStorage.setItem("isVideoDisabled", JSON.stringify(isVideoDisabled));
  }, [isVideoDisabled]);

  const toggleVideo = () => {
    setIsVideoDisabled(!isVideoDisabled);
  };

  return (
    <React.StrictMode>
      <button id="toggle-video" onClick={toggleVideo} className=" ">
        {isVideoDisabled ? <FaPlay /> : <FaPause />}
        <span>{isVideoDisabled ? " Enable YouTube" : " Disable YouTube"}</span>
      </button>
      <Background id="background" isVideoDisabled={isVideoDisabled} />
    </React.StrictMode>
  );
}

const root = document.getElementById("root");
if (root !== null) {
  createRoot(root).render(
    <React.StrictMode>
      <FaviconIcos />
      <App />
    </React.StrictMode>
  );
}

const background = document.getElementById("background");
if (background !== null) {
  createRoot(background).render(
    <React.StrictMode>
      <BackgroundWithButton />
    </React.StrictMode>
  );
}

function ToggleVideoButton() {
  const [isVideoDisabled, setIsVideoDisabled] = useState(() => {
    const storedValue = localStorage.getItem("isVideoDisabled");
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });

  useEffect(() => {
    localStorage.setItem("isVideoDisabled", JSON.stringify(isVideoDisabled));
  }, [isVideoDisabled]);

  const toggleVideo = () => {
    setIsVideoDisabled(!isVideoDisabled);
    window.location.reload(); // Add this line
  };

  return (
    <button
      id="toggle-video"
      onClick={toggleVideo}
      className="flex bg-transparent trajan-bold text-xl"
    >
      {isVideoDisabled ? <FaPlay /> : <FaPause />}
      <span>{isVideoDisabled ? " Enable YouTube" : " Disable YouTube"}</span>
    </button>
  );
}

document.addEventListener("DOMContentLoaded", (event) => {
  const backgroundButton = document.getElementById("background-button");
  if (backgroundButton !== null) {
    ReactDOM.render(<ToggleVideoButton />, backgroundButton);
  }
});

import React, { useState, useEffect } from "react";
import Background from "./components/layout/Background";
import SkillsBottom from "./components/skills-bottom/SkillsBottom";
import GlobalResize from "./components/layout/GlobalResize";

import { SpeedInsights } from "@vercel/speed-insights/react";
import { inject } from "@vercel/analytics";
import { FaPlay, FaPause } from "react-icons/fa";

inject();

function App() {
  //

  return (
    <>
      <GlobalResize />
      <SkillsBottom />

      {/* </GlobalResize> */}
      {/* <button
        id="toggle-video"
        onClick={toggleVideo}
        style={{
          position: "fixed",

          // height: "100vh",
          marginLeft: 0,
          marginRight: 0,
          right: 0,
        }}
      >
        {isVideoDisabled ? <FaPlay /> : <FaPause />}
        <span>{isVideoDisabled ? " Enable YouTube" : " Disable YouTube"}</span>
      </button> */}
      <SpeedInsights />
    </>
  );
}

export default App;

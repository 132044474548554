import React, { useEffect } from "react";

function GlobalResize() {
  useEffect(() => {
    const resize = () => {
      const z = Math.floor((document.body.clientWidth / 2560) * 10000) / 10000;
      const elements = ["bottom", "top"].map((id) =>
        document.getElementById(id)
      );

      elements.forEach((element) => {
        if (element) {
          element.style.transform = `scale(${z})`;
          // element.style.transformOrigin = "center";
        }
      });
    };

    // Call resize function initially
    resize();

    // Add event listener
    window.addEventListener("resize", resize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", resize);
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return null;
}

export default GlobalResize;
